import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import 'typeface-montserrat';
import 'typeface-open-sans';

interface LayoutProps {
  hideMenu?: boolean;
  login?: boolean;
}
const Layout: React.FC<LayoutProps> = function (props) {
  const { children, hideMenu, login } = props;

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header title={data.site.siteMetadata.title} hideMenu={hideMenu} />
      <div>
        <main>{children}</main>
        <Footer login={login} title={data.site.siteMetadata.title}></Footer>
      </div>
    </>
  );
};

export default Layout;
