import React, {
  FormEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import Layout from '../layouts/Layout';
import { Link, navigate, PageProps } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import useFirebaseAuth from '../hooks/useFirebaseAuth';
import SEO from '../components/SEO';
import LinkButton from '../components/form-elements/LinkButton';
import Input from '../components/form-elements/Input';
import Checkbox from '../components/form-elements/Checkbox';
import { device } from '../constants';

interface WrapperProps {
  color: string;
}

const CheckboxWrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  color: ${props => props.color};
  margin-bottom: ${props => props.theme.paddings.halfpd * 1.5}px;

  & span {
    margin-left: ${props => props.theme.paddings.halfpd * 0.6}px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1 0 35%;
  padding: ${props => props.theme.paddings.pd * 1.5}px;
`;

const ImageBackground = styled.div`
  background-image: url('images/design/login.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: none;

  @media screen and ${device.laptop} {
    flex: 1 0 50%;
    display: block;
  }
`;

const ErrorMessage = styled.span`
  padding: ${props => props.theme.paddings.halfpd}px;
  margin-bottom: ${props => props.theme.paddings.halfpd * 1.5}px;
  background: #f4adad;
  color: #e22424;
`;

const LinkButtonContainer = styled.div`
  max-width: 50%;
`;

const FancyHeading = styled.span`
  font-weight: 500;
  font-size: 72px;
  margin-bottom: ${props => props.theme.paddings.pd * 1.5}px;

  & span:after {
    content: '';
    display: block;
    width: 100%;
    height: 6px;
    background-color: ${props => props.theme.colors.purple};
  }
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const IS_LOGIN_PAGE_DISABLED = true;

const LoginPage: React.FC<PageProps> = function (props) {
  const { location } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { loading, currentUser } = useFirebaseAuth();

  useEffect(() => {
    if (IS_LOGIN_PAGE_DISABLED) {
      navigate('/');
    }
  }, []);

  // Check if the current user is logged in
  useEffect(() => {
    if (!loading && currentUser !== null) {
      navigate('/dashboard');
    }
  }, [loading, currentUser]);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      async function login() {
        try {
          const user = await firebase
            .auth()
            .signInWithEmailAndPassword(email, password);

          // Success
          navigate('/dashboard');
        } catch (e) {
          setErrorMessage(e.message);
        }
      }

      login();
    },
    [email, password]
  );

  const changePersistenceState = useCallback((e: MouseEvent) => {
    const persistenceState = e.target.checked
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;

    firebase
      .auth()
      .setPersistence(persistenceState)
      .then(function () {})
      .catch(function (error) {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }, []);

  if (IS_LOGIN_PAGE_DISABLED) {
    return null;
  }

  return (
    <Layout hideMenu={true} login={true}>
      <SEO title="Login" path={location?.pathname} />
      {!loading && currentUser === null && (
        <Wrapper>
          <ImageBackground></ImageBackground>
          <Form>
            <FancyHeading>
              <span>welcome</span> back!
            </FancyHeading>
            <Input
              onChange={(e: any) => setEmail(e.target.value)}
              type="email"
              value={email}
              placeholder="Username or email address"
              marginBottom={20}
            />
            <Input
              onChange={(e: any) => setPassword(e.target.value)}
              type="password"
              value={password}
              placeholder="Password"
              marginBottom={20}
            />
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <CheckboxWrapper color="#79175B">
              <Checkbox color="#79175B" onClick={changePersistenceState} />
              <span>Remember Me</span>
            </CheckboxWrapper>
            <LinkButtonContainer>
              <LinkButton
                noLink={true}
                onClick={handleSubmit}
                buttonText={'Log in'}
                link={'#'}
                buttonType="primary"
              />
            </LinkButtonContainer>
            <br />
            <br />
            <Link to="/forgot-password">Forgot Password?</Link>
          </Form>
        </Wrapper>
      )}
    </Layout>
  );
};

export default LoginPage;
