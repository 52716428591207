import React from 'react';
import styled from 'styled-components';

interface CheckboxProps {
  color: string;
  checked?: boolean;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
}

const CheckboxWrapper = styled.input<CheckboxProps>`
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  appearance: none;
  border: 3px solid ${props => props.color};
  background-clip: content-box;
  padding: 4px;

  &:checked {
    background-color: ${props => props.color};
  }
`;

const Checkbox: React.FC<CheckboxProps> = function (props) {
  const { color, checked, onClick } = props;

  return (
    <CheckboxWrapper
      color={color}
      type="checkbox"
      checked={checked}
      onClick={onClick}
    />
  );
};

export default Checkbox;
